<template>
  <v-card height="100%" width="100%" class="grey darken-3 fill-height">
    <v-row
      height="100%"
      dense
      width="100%"
      no-gutters
    >
      <v-col cols="12">
        <track-player-card ref="trackCard" :track="mix" :showButton="false" />
      </v-col>
      <v-col cols="12">
              <v-divider></v-divider>
        <v-card-text>
          {{ mix.description }}
        </v-card-text>
      </v-col>
      <v-col cols="12">
        <v-file-input
        v-if="!mix.trackList"
          v-model="uploadedFile"
          accept="*.nml"
          label="Upload Traktor Playlist"
          outlined
          dense
        >
        </v-file-input>
        <v-simple-table dark v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Title
                </th>
                <th class="text-left">
                  Artist
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="track in mix.trackList"
                :key="track.title"
              >
                <td>{{ track.title }}</td>
                <td>{{ track.artist }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { saveAs } from 'file-saver'
var convert = require('xml-js')
export default {
  name: 'Tunes',
  components: {
    TrackPlayerCard: () => import('../../components/TrackPlayerCard.vue')
  },
  data () {
    return {
      mix: null,
      uploadedFile: []
    }
  },
  computed: {
    mixes () {
      return this.$store.state.tunes.mixes
    }
  },
  watch: {
    uploadedFile (fileToUpload) {
      const reader = new FileReader()
      if (fileToUpload === null) return
      reader.onload = e => {
        const tracks = []
        const rawTrackList = JSON.parse(convert.xml2json(e.target.result, { compact: true, spaces: 4 })).NML.COLLECTION.ENTRY
        rawTrackList.forEach(t => {
          tracks.push({
            title: t._attributes.TITLE,
            artist: t._attributes.ARTIST
            // key: `${t.LOCATION._attributes.VOLUME}${t.LOCATION._attributes.DIR}${t.LOCATION._attributes.FILE}`
          })
        })
        // const orderedList = JSON.parse(convert.xml2json(e.target.result, { compact: true, spaces: 4 })).NML.PLAYLISTS.NODE.SUBNODES.NODE.SUBNODES.NODE.PLAYLIST.ENTRY
        // console.log(orderedList.length)
        // const orderedTracks = []
        // orderedList.forEach(ot => {
        //   const track = tracks.find(t => t.key === ot.PRIMARYKEY._attributes.KEY)
        //   delete track.key
        //   orderedTracks.push(track)
        // })
        this.mix.trackList = tracks
        this.mix = { ...this.mix }
        const blob = new Blob([JSON.stringify(tracks)], { type: 'text/plain;charset=utf-8' })
        saveAs(blob, `${this.mix.title}.json`)
      }
      reader.readAsText(fileToUpload)
    }
  },
  created () {
    const mixId = this.$route.params.id
    this.mix = this.mixes.find(t => t.id === mixId)
  }
}
</script>
